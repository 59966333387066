import React, { Component } from 'react';
import styled, {css} from 'styled-components';

class Container extends Component {
  render() {
    return (
      <div className={`${ this.props.className + ' container'}`}>
        {this.props.children}
      </div>
    );
  }
}

export default styled(Container)`
  width: 85%;
  max-width: 1350px;
  margin: 0 auto;
  ${props => props.small && css`
    max-width: 700px;
  `}
`;
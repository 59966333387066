import React, { Component } from 'react';
import styled, {css} from 'styled-components';

class TextBox extends Component {
  render() {
    return (
      <article className={`${ this.props.className}`}>
        {this.props.children}
      </article>
    );
  }
}

export default styled(TextBox)`
  width: 85%;
  max-width: 925px;
  margin: 0 auto;
  padding: 85px 65px;
  border: 3px solid var(--text-color);
  h4,
  p:not(:last-child) {
    margin: 0 0 30px;
  }
  h4 {
    font-size: 20px;
  }
  ul {
    list-style: disc;
    margin: 0 0 0 20px;
    li:not(:last-child) {
      margin: 0 0 10px;
    }
  }
  a {
    color: inherit;
    transition: color .5s;
    &:hover {
      color: var(--accent-color--red);
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 45px 35px;
  }
  ${props => props.borderPurple && css`
    border-color: var(--accent-color--purple);
  `}
  ${props => props.borderRed && css`
    border-color: var(--accent-color--red);
  `}
  ${props => props.borderYellow && css`
    border-color: var(--accent-color--yellow);
  `}
  ${props => props.boxSmall && css`
    max-width: 775px;
  `}
  ${props => props.boxXs && css`
    max-width: 600px;
  `}
  ${props => props.textBoxLeft && css`
    margin: 100px 0 -50px;
    @media only screen and (max-width: 767px) {
      margin: 90px 0 -30px;
      padding: 65px 35px;
    }
  `}
  ${props => props.textBoxRight && css`
    margin: 0 0 0 auto;
    @media only screen and (max-width: 767px) {
      padding: 65px 35px;
    }
  `}  
  ${props => props.textLarge && css`
    text-align: center;
    padding: 55px 65px;
    @media only screen and (max-width: 767px) {
      padding: 55px 35px;
    }
  `} 
`;
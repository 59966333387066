import React, { Component } from 'react';
import styled, {css} from 'styled-components';

class Button extends Component {
  render() {
    return (
      <a
        className={`${ this.props.className + ' btn'}`}
        href={this.props.url}
        onClick={this.props.onClick}
        target={this.props.target}
      >
        {this.props.text}  
      </a>
    );
  }
}

export default styled(Button)`
  display: block;
  min-width: 160px;
  max-width: 330px;
  font-size: 20px;
  font-weight: var(--fw--bold);
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  margin: 0 auto;
  padding: 20px 25px;
  background: var(--accent-color--yellow);
  color: var(--text-color);
  border: 3px solid var(--accent-color--yellow);
  transition: all .35s;
  &:hover {
    cursor: pointer;
    background: transparent;
  }
  @media only screen and (max-width: 500px) {
      max-width: 200px;
      font-size: 15px;
  }
  ${props => props.bgPurple && css`
    background: var(--accent-color--purple);
    border-color: var(--accent-color--purple);
  `}
  ${props => props.bgRed && css`
    background: var(--accent-color--red);
    border-color: var(--accent-color--red);
  `}
  ${props => props.btnSmall && css`
    padding: 10px 15px;
    background: transparent;
    border-color: var(--text-color);
  `}
  ${props => props.btnTextBox && css`
    margin: -34px auto 0;
    &:hover {
      background: var(--bg--dark);
    }
    @media only screen and (max-width: 500px) {
      max-width: 170px;
      padding: 20px 5px;
    }
  `}
  ${props => props.btnCTA && css`
    background: 0;
    border-color: var(--text-color);
    &:hover {
      background: var(--text-color);
      color: var(--brand-color);
    }
  `}
`;
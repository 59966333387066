import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './components/Container';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';
import TextBox from './components/TextBox';

import aboutMap from './assets/img/home__about--map.png';
import dan from './assets/img/contact/portrait--dan.jpg';
import iconEmail from './assets/img/icons/icon--email.svg';
import phil from './assets/img/contact/portrait--phil.jpg';
import iconTwitter from './assets/img/icons/icon--twitter.svg';

const SectionAbout = styled.section`
  background: var(--bg--dark) top left url(${aboutMap}) no-repeat;
  background-size: cover;
  padding: 115px 0 175px;
`;

const Organizer = styled.div`
  h3 {
    font-size: 24px;
    font-weight: var(--fw--reg);
    margin: 0 0 15px;
  }
  p {
    max-width: 600px;
    font-size: 18px;
  }
  ul {
    display: flex;
    max-width: 200px;
    margin: 25px 0 0;
    li {
      width: 50%;
      transition: opacity .5s;
      &:hover {
        opacity: .7;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    &:not(:last-of-type) {
      margin: 0 0 40px;
      padding: 0 0 40px;
      border-bottom: 3px solid var(--text-color);
    }
  }
  @media only screen and (min-width: 600px) {
    div {
      padding: 0 30px;
    }
    flex-direction: row;
  }
`;

const Portrait = styled.img`
  @media only screen and (min-width: 600px) {
    align-self: center;
  }
  @media only screen and (max-width: 599px) {
    align-self: flex-start;
    margin: 25px 0;
  }
`;

class Contact extends Component {
  componentDidMount() {
    document.title = "NSNorth 2019 | Contact";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main className={`${ this.props.className}`}>
        <SectionHero bgSchedule heading="Contact" />
        <SectionAbout>
          <Container>
            <SectionHeading borderRed heading="Organizers" />
            <div className="grid">
              <Organizer className="grid__col-lg-6 grid__col-12">
                <div>
                  <h3>Dan Byers</h3>
                  <p>Consultant building mobile apps and stronger communities. Loves spending time with his family and long distance running. 100% fuelled by good coffee.</p>
                  <ul>
                    <li>
                      <a href="mailto:dan@nsnorth.ca">
                        <img src={iconEmail} alt="Icon Email" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/_danbyers" target="_blank" rel="noopener noreferrer">
                        <img src={iconTwitter} alt="Icon Twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
                <Portrait src={dan} alt="Portrait of Dan" />
              </Organizer>
              <Organizer className="grid__col-lg-6 grid__col-12">
                <Portrait src={phil} alt="Portrait of Dan" />
                <div>
                  <h3>Philippe Casgrain</h3>
                  <p>Long-time Mac and Cocoa developer, spends a lot of time in the community. Co-founder of the local CocoaHeads chapter and the Cacaocast podcast. Bikes year-round, and loves his wife and three daughters.</p>
                  <ul>
                    <li>
                      <a href="mailto:phil@nsnorth.ca">
                        <img src={iconEmail} alt="Icon Email" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/philippec" target="_blank" rel="noopener noreferrer">
                        <img src={iconTwitter} alt="Icon Twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </Organizer>
            </div>
          </Container>
        </SectionAbout>
      </main>
    );
  }
}

export default Contact;

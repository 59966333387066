import React, { Component } from 'react';
import styled from 'styled-components';

import iconFb from '../assets/img/icons/icon--fb.svg';
import iconTwitter from '../assets/img/icons/icon--twitter.svg';

class SocialMediaMenu extends Component {
  render() {
    return (
      <ul className={`${ this.props.className + ' grid'}`}>
        <li>
          <a className="box" href="https://www.facebook.com/nsnorthconf" target="_blank" rel="noopener noreferrer">
            <img className="icon--front" src={iconFb} alt="Logo Facebook" />
            <img className="icon--back" src={iconFb} alt="Logo Facebook" />
          </a>
        </li>
        <li>
          <a className="box" href="https://twitter.com/nsnorth" target="_blank" rel="noopener noreferrer">
            <img className="icon--front" src={iconTwitter} alt="Logo Twitter" />
            <img className="icon--back" src={iconTwitter} alt="Logo Twitter" />
          </a>
        </li>
      </ul>
    );
  }
}

export default styled(SocialMediaMenu)`
  width: 145px;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 0 auto;
  li {
    width: 55px;
    height: 55px;
    .box {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      perspective-origin: 50px 50px;
      transform-origin: 50px 50px;
      transition-delay: 0s;
      transition: transform 0.2s ease-out;
      transform-style: preserve-3d;
    }
    .icon--front,
    .icon--back {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 13px;
    }
    .icon--front {
      background: var(--bg--dark);
      transform: translate3d(0px, 0px, 30px) rotateY(0deg);
      transition: background 0.2s ease-out;
    }
    .icon--back {
      background: #464646;
      transform: translate3d(29px, 0px, 20px) rotateY(90deg);
      transition: background 0.2s ease-out;
    }
    .box:hover {
      transform: rotateY(-90deg);
      transition-delay: .35s;
      .icon--front {
        background: #464646;
        transition-delay: .35s;
      }
      .icon--back {
        background: var(--bg--dark);
        transition-delay: .35s;
      }
    }
  }
`;
import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './components/Container';
import PictureGallery from './components/PictureGallery';
import SectionHero from './components/SectionHero';

const SectionPictures = styled.section`
  padding: 115px 0 175px;
`;

const Text = styled.p`
  text-align: center;
  a {
    color: inherit;
  }
`;

class Photos extends Component {
  componentDidMount() {
    document.title = "NSNorth | Photos";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main className={`${ this.props.className}`}>
        <SectionHero bgVenue heading="Photos" />
        <SectionPictures id="photos">
          <Container>
            <PictureGallery />
          </Container>
        </SectionPictures>
      </main>
    );
  }
}

export default Photos;
import React, { Component } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    max-height: 0;
  }

  to {
    opacity: 1;
    max-height: 500px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  fieldset {
    position: relative;
    width: 100%;
    margin: 25px 0 0;
  }
  input[type='email'],
  button {
    font-size: 20px;
    padding: 17px 15px;
    background: 0;
    color: var(--text-color);
    border: 3px solid var(--text-color);
  }
  input[type='email'] {
    width: 100%;
    height: auto;
    font-weight: var(--fw--light);
  }
  button {
    font-weight: var(--fw--bold);
    text-transform: uppercase;
    transition: all .5s;
    &:hover {
      cursor: pointer;
      background: var(--text-color);
      color: var(--brand-color);
    }
    @media only screen and (min-width: 401px) {
      position: absolute;
      top: 0;
      right: 0;
    }
    @media only screen and (max-width: 400px) {
      margin: 35px 0 0;
    }
  }
  .form__status {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    text-align: left;
    padding: 20px 15px;
    background: var(--text-color);
    animation: ${fadeIn} .5s linear forwards;
    @media only screen and (max-width: 400px) {
      margin: 35px 0 0;
    }
  }
`;

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <fieldset>
      <input ref={node => (email = node)} type="email" placeholder="Enter your email" />
      <button onClick={submit}>Submit</button>
      {status === "error" && (
        <div
          style={{ color: "red" }}
          className="form__status"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          className="form__status"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </fieldset>
  );
};

class MailchimpForm extends Component {
  render() {
    const url =
      "https://nsnorth.us6.list-manage.com/subscribe/post?u=64044047403e3c4e0395904f6&amp;id=1c0c4d41ca";

    return (
      <FormContainer>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        />
      </FormContainer>
    );
  }
}

export default MailchimpForm;
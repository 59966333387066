import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import ButtonToggleMenu from './ButtonToggleMenu';
import Container from './Container';
import Logo from './Logo';

const fadeIn = keyframes`
  from {
    background: transparent;
  }

  to {
    background: var(--bg--dark);
  }
`;

const HeaderMenu = styled.ul`
  li {
    font-size: 20px;
    font-weight: var(--fw--bold);
    padding: 0 22px;
    a:not(.btn) {
      padding: 10px 0;
      border-bottom: 3px solid transparent;
      transition: all .5s;
    }
    &:nth-child(3n+1) a:hover{
      border-color: var(--accent-color--yellow);
    }
    &:nth-child(3n+2) a:hover {
      border-color: var(--accent-color--red);
    }
    &:nth-child(3n+3) a:hover {
      border-color: var(--accent-color--purple);
    }
    @media only screen and (min-width: 992px) {
      &:nth-child(3n+1) a.active {
        border-color: var(--accent-color--yellow);
      }
      &:nth-child(3n+2) a.active {
        border-color: var(--accent-color--red);
      }
      &:nth-child(3n+3) a.active {
        border-color: var(--accent-color--purple);
      }
    } 
    &:last-child {
      padding-right: 0;
    }
  }
  a {
    display: block;
    color: inherit;
  }
  @media only screen and (max-width: 1200px) {
    li {
      font-size: 17px;
      padding: 0 13px;
      .btn {
        font-size: inherit;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: 75px 0 0;
    background: var(--brand-color);
    transform: translate(100%, 0);
    transition: all 1s;
    li {
      width: 100%;
      text-align: center;
      padding: 15px 0;
      &:not(:last-child) {
        border-bottom: 2px solid var(--text-color);
      }
      &:last-child {
        padding: 30px 0 0;
      }
    }
    &.menu--show {
      display: flex;
      transform: translate(0, 0);
    }
  }
`;

class Header extends Component {
  state = {
    showMenu: false,
  }

  constructor(props) {
    super(props);

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    if(this.state.showMenu){
      this.setState({'showMenu': false});
    } else{
      this.setState({'showMenu': true});
    }
  }

  render() {
    return (
      <header className={`${ this.props.className}`}>
        <Container className="grid">
          <Logo />
          <nav>
            <HeaderMenu
              className={this.state.showMenu ? 'grid menu--show' : 'grid'}
              onClick={this.toggleMenu}
            >
              <li>
                <NavLink to="/videos" activeClassName="active">Videos</NavLink>
              </li>
              <li>
                <NavLink to="/photos" activeClassName="active">Photos</NavLink>
              </li>
              <li>
                <NavLink to="/podcasts" activeClassName="active">Podcasts</NavLink>
              </li>
              <li>
                <NavLink to="/speakers" activeClassName="active">Speakers</NavLink>
              </li>
              <li>
                <NavLink to="/venue" activeClassName="active">Venue</NavLink>
              </li>
              <li>
                <NavLink to="/contact" activeClassName="active">Contact</NavLink>
              </li>
            </HeaderMenu>
          </nav>
          <ButtonToggleMenu
            className={this.state.showMenu ? ' menu--show' : ''}
            onClick={this.toggleMenu}
          />
        </Container>
      </header>
    );
  }
}

export default styled(Header)`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 0;
  animation: ${fadeIn} .75s 3s linear forwards;
  .container {
    align-items: center;
    justify-content: space-between;
  }
`;

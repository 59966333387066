import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './components/Container';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';

const SectionSchedule = styled.section`
  padding: 115px 0 125px;
  .container__schedule {
    position: relative;
  }
  @media only screen and (max-width: 991px) {
    padding: 45px 0 55px;
    h2 {
      margin: 0 0 45px;
    }
  }
`;

const ScheduleTimes = styled.ul`
  max-width: 950px;
  margin: 0 auto;
  border: 3px solid var(--border);
  li {
    font-size: 24px;
    padding: 27px 30px;
    &:not(:last-child) {
      border-bottom: 3px solid var(--border);
    }
  }
  @media only screen and (max-width: 550px) {
    opacity: 0; 
  }
`;

const ScheduleEvents = styled.ul`
  position: absolute;
  top: 3px;
  left: calc(50% - 237.5px);
  width: 100%;
  max-width: 475px;
  margin: 0 auto;
  li {
    position: relative;
    margin: 0 0 3px;
    padding: 15px 20px 15px 45px;
    background: var(--bg--dark);
    time {
      display: block;
      font-size: 16px;
      margin: 0 0 11px;
    }
    h4 {
      font-size: 20px;
    }
    &:before {
      position: absolute;
      top: 15px;
      left: 20px;
      display: block;
      width: 10px;
      height: 50px;
      content: '';
      background: var(--text-color);
    }
    &.bgPurple:before {
      background: var(--accent-color--purple);
    }
    &.bgRed:before {
      background: var(--accent-color--red);
    }
    &.bgYellow:before {
      background: var(--accent-color--yellow);
    }
    &.hours--0-5 {
      height: 39.5px;
      padding-top: 8px;
      padding-bottom: 8px;
      time,
      h4 {
        display: inline-block;
      }
      time {
        margin: 0 15px 0 0;
      }
      &:before {
        top: 12px;
        height: 20px;
      }
    }
    &.hours--1-15 {
      height: 101px;
    }
    &.hours--1-5 {
      height: 124.5px;
    }
    &.hours--1-5b {
      height: 119px;
    }
    &.hours--2 {
      height: 167px;
    }
    &.hours--2-5 {
      height: 214px;
    }
    &.hours--2-5b {
      height: 209px;
    }
    &.hours--2-5c {
      height: 204px;
    }
    &.hours--3 {
      height: 252px;
    }
    &.hours--3-5 {
      height: 295px;
    }
    &.hours--4 {
      height: 337px;
    }
    &.hours--4-5 {
      height: 422px;
    }
  }
  @media only screen and (max-width: 991px) {
    max-width: 345px;
    left: calc(50% - 172.5px);
  }
  @media only screen and (max-width: 767px) {
    left: auto;
    right: 3px;
  }
  @media only screen and (max-width: 550px) {
    left: 0;
    right: auto;
    max-width: 100%;
    li {
      padding: 15px 10px 15px 30px;
      h4 {
        font-size: 18px;
      }
      &:before {
        left: 10px;
      }
      &.hours--0-5 {
        time {
          margin: 0 10px 0 0;
        }
      }
    }
  }
  @media only screen and (max-width: 375px) {
    li {
      time {
        font-size: 14px;
      }
      h4 {
        font-size: 16px;
      }
      &.hours--0-5 {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }
`;

class Schedule extends Component {
  componentDidMount() {
    document.title = "NSNorth 2019 | Schedule";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main className={`${ this.props.className}`}>
        <SectionHero bgSchedule heading="Schedule" />
        <SectionSchedule>
          <Container>
            <SectionHeading heading="Friday, April 26th" />
            <div className="container__schedule">
              <ScheduleTimes>
                <li>08.00</li>
                <li>09.00</li>
                <li>10.00</li>
                <li>11.00</li>
                <li>12.00</li>
                <li>13.00</li>
                <li>14.00</li>
                <li>15.00</li>
                <li>16.00</li>
                <li>17.00</li>
                <li>18.00</li>
                <li>19.00</li>
                <li>20.00</li>
                <li>21.00</li>
                <li>22.00</li>
                <li>23.00</li>
              </ScheduleTimes>
              <ScheduleEvents>
                <li className="bgYellow">
                  <time>08.00 - 09.00</time>
                  <h4>Workshop Registration</h4>
                </li>
                <li className="bgRed hours--3">
                  <time>09.00 - 12.00</time>
                  <h4>Workshop Begins</h4>
                </li>
                <li className="bgYellow hours--1">
                  <time>12.00 - 13.00</time>
                  <h4>Lunch</h4>
                </li>
                <li className="bgRed hours--4">
                  <time>13.30 - 17.00</time>
                  <h4>Workshop Continues</h4>
                </li>
                <li className="bgYellow hours--0-5">
                </li>
                <li className="bgYellow hours--2">
                  <time>17.30 - 19.30</time>
                  <h4>Registration & Opening reception</h4>
                </li>
                <li className="bgPurple hours--1-5">
                  <time>19.30 - 21.00</time>
                  <h4>Opening address & Keynote</h4>
                </li>
                <li className="bgYellow hours--3">
                  <time>21.00 - 23.00</time>
                  <h4>After party</h4>
                </li>
              </ScheduleEvents>
            </div>
          </Container>
        </SectionSchedule>
        <SectionSchedule>
          <Container>
            <SectionHeading heading="Saturday, April 27th" />
            <div className="container__schedule">
              <ScheduleTimes>
                <li>08.00</li>
                <li>09.00</li>
                <li>10.00</li>
                <li>11.00</li>
                <li>12.00</li>
                <li>13.00</li>
                <li>14.00</li>
                <li>15.00</li>
                <li>16.00</li>
                <li>17.00</li>
                <li>18.00</li>
                <li>19.00</li>
                <li>20.00</li>
                <li>21.00</li>
                <li>22.00</li>
                <li>23.00</li>
              </ScheduleTimes>
              <ScheduleEvents>
                <li className="bgYellow hours--1">
                  <time>08.00 - 09.00</time>
                  <h4>Breakfast</h4>
                </li>
                <li className="bgPurple hours--3">
                  <time>09.00 - 12:00</time>
                  <h4>Sessions</h4>
                </li>
                <li className="bgYellow">
                  <time>12.00 - 13.00</time>
                  <h4>Lunch</h4>
                </li>
                <li className="bgPurple hours--4">
                  <time>13.00 - 17.00</time>
                  <h4>Sessions</h4>
                </li>
                <li className="bgYellow hours--2">
                  <time>17.00 - 19.00</time>
                  <h4>Offsite Activity: La Grande Roue de Montr&eacute;al</h4>
                </li>
                <li className="bgYellow hours--2-5">
                  <time>19.00 - 21.30</time>
                  <h4>Banquet Dinner</h4>
                </li>
                <li className="bgYellow hours--2-5c">
                  <time>21.30 - 23.00</time>
                  <h4>Board Game Night</h4>
                </li>
              </ScheduleEvents>
            </div>
          </Container>
        </SectionSchedule>
        <SectionSchedule>
          <Container>
            <SectionHeading heading="Sunday, April 28th" />
            <div className="container__schedule">
              <ScheduleTimes>
                <li>08.00</li>
                <li>09.00</li>
                <li>10.00</li>
                <li>11.00</li>
                <li>12.00</li>
                <li>13.00</li>
                <li>14.00</li>
                <li>15.00</li>
                <li>16.00</li>
                <li>17.00</li>
              </ScheduleTimes>
              <ScheduleEvents>
                <li className="bgYellow hours--0-5">
                </li>
                <li className="bgYellow hours--1">
                  <time>08.30 - 09.30</time>
                  <h4>Breakfast</h4>
                </li>
                <li className="bgPurple hours--3">
                  <time>09.30 - 12.30</time>
                  <h4>Sessions</h4>
                </li>
                <li className="bgYellow">
                  <time>12.30 - 13.30</time>
                  <h4>Lunch</h4>
                </li>
                <li className="bgPurple hours--3-5">
                  <time>13.30 - 17.00</time>
                  <h4>Sessions</h4>
                </li>
                <li className="bgPurple">
                  <time>17.00</time>
                  <h4>Closing remarks / Conference ends</h4>
                </li>
              </ScheduleEvents>
            </div>
          </Container>
        </SectionSchedule>
      </main>
    );
  }
}

export default Schedule;
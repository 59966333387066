import React, { Component } from 'react';
import styled from 'styled-components';

import Button from './components/Button';
import Container from './components/Container';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';

const Section = styled.section`
  padding: 115px 0 185px;
  @media only screen and (max-width: 767px) {
    padding: 115px 0 85px;
  }
  .grid {
    justify-content: space-around;
  }
  .btn {
    display: flex;
    justify-content: center;
    margin: 0 20px;
    @media only screen and (max-width: 767px) {
      width: 100%;
      max-width: 450px;
      flex: 0 0 100%;
      margin: 0 auto 45px;
    }
  }
`;

const Text = styled.p`
  text-align: center;
  margin: 115px 0 125px;
  @media only screen and (max-width: 767px) {
    margin: 85px 0 75px;
  }
`;

class ThankYou extends Component {
  componentDidMount() {
    document.title = "NSNorth 2019 | Thank You";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main className={`${ this.props.className}`}>
        <SectionHero bgSchedule heading="Upload submitted successfully" />
        <Section>
          <Container>
            <SectionHeading marginSmall borderPurple heading="Thank you! We will review your submission shortly and let you know once it is available on the site." />
            <Text>Please check out our other photos, videos or podcasts:</Text>
            <div className="grid">
              <Button
                className="grid__col-auto"
                bgYellow
                url="/photos"
                text="View Photos"
              />
              <Button
                className="grid__col-auto"
                bgRed url="/videos"
                text="View Videos"
              />
              <Button
                className="grid__col-auto"
                bgPurple url="/podcasts"
                text="View Podcasts"
              />
            </div>
          </Container>
        </Section>
      </main>
    );
  }
}

export default ThankYou;

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './assets/css/App.css';

import Contact from './Contact';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './Home';
import NotFound from './NotFound';
import Photos from './Photos';
import PhotoUpload from './PhotoUpload';
import Podcasts from './Podcasts';
import Schedule from './Schedule';
import Speakers from './Speakers';
import Sponsors from './Sponsors';
import ThankYou from './ThankYou';
import Venue from './Venue';
import Videos from './Videos';
import Diversity from './Diversity';
import Workshop from './Workshop';
import Conduct from './CodeOfConduct';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/schedule" component={Schedule} />
            <Route exact path="/speakers" component={Speakers} />
            <Route exact path="/diversity" component={Diversity} />
            <Route exact path="/photos" component={Photos} />
            <Route exact path="/photo-upload" component={PhotoUpload} />
            <Route exact path="/podcasts" component={Podcasts} />
            <Route exact path="/sponsors" component={Sponsors} />
            <Route exact path="/thank-you" component={ThankYou} />
            <Route exact path="/venue" component={Venue} />
            <Route exact path="/videos" component={Videos} />
            <Route exact path="/workshop" component={Workshop} />
            <Route exact path="/code-of-conduct" component={Conduct} />
            <Route path="*" component={NotFound} />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;

import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './components/Container';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';

const SectionDiversity = styled.section`
  padding: 25px 0 175px;
  .container {
    padding: 85px 0 25px;
    &:not(:last-child) {
      border-bottom: 3px solid var(--text-color);
    }
  }
  a:hover {
    color: var(--accent-color--yellow);
  }
  a {
    color: var(--accent-color--red);
  }
  p {
    max-width: 800px;
    margin: 0 auto 45px;
  }
`;

class Diversity extends Component {
  componentDidMount() {
    document.title = "NSNorth 2019 | Diversity Support";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main className={`${ this.props.className}`}>
        <SectionHero bgSchedule heading="Diversity Support" />
        <SectionDiversity>
          <Container>
            <p>NSNorth strives to genuinely create a diverse, safe, and welcoming event for the Apple designer and developer community.</p>
            <p>This year, we are once again offering Diversity Support Tickets to allow an even more diverse audience to participate in the conference.</p>
          </Container>
          <Container>
            <SectionHeading marginSmall borderYellow heading="How do Diversity Support Tickets work?" />
            <p>When you purchase your conference ticket you have the option to enable another person to attend NSNorth by covering a share of their ticket with a &ldquo;Diversity Support Ticket Donation&rdquo;. We thank you and send high fives in advance for helping us make this event, and our community, a more inclusive group.</p>
          </Container>
          <Container>
            <SectionHeading marginSmall borderPurple heading="I am interested in sponsoring in a different way. Can I help?" />
            <p>Support from sponsors and partners is greatly needed. Please get in touch directly with one of us: <a href="mailto:dan@nsnorth.ca">Dan</a> or <a href="mailto:phil@nsnorth.ca">Phil</a>.</p>
          </Container>
          <Container>
            <SectionHeading marginSmall borderYellow heading="Attribution" />
            <p>Support Program: <a href="http://2015.cssconf.eu/diversity-support-tickets/">CSSCONF EU</a>.</p>
          </Container>
        </SectionDiversity>
      </main>
    );
  }
}

export default Diversity;
import React, { Component } from 'react';
import styled from 'styled-components';

import location2 from '../assets/img/location/location--2.jpg';
import location3 from '../assets/img/location/location--3.jpg';
import location4 from '../assets/img/location/location--4.jpg';
import location5 from '../assets/img/location/location--5.jpg';
import location6 from '../assets/img/location/location--6.jpg';
import location7 from '../assets/img/location/location--7.jpg';
import location8 from '../assets/img/location/location--8.jpg';
import location9 from '../assets/img/location/location--9.jpg';
import location10 from '../assets/img/location/location--10.jpg';

class LocationGallery extends Component {
  render() {
    return (
      <div className={`${ this.props.className}`}>
        <img src={location2} alt="Location" />
        <img src={location3} alt="Location" />
        <img src={location4} alt="Location" />
        <img src={location5} alt="Location" />
        <img src={location6} alt="Location" />
        <img src={location7} alt="Location" />
        <img src={location8} alt="Location" />
        <img src={location9} alt="Location" />
        <img src={location10} alt="Location" />
      </div>
    );
  }
}

export default styled(LocationGallery)`
  column-count: 4;
  column-gap: 25px;
  margin: 25px 0 0;
  img {
    width: 100%;
    margin: 0 0 25px;
  }
  @media only screen and (max-width: 991px) {
    column-count: 3;
  }
  @media only screen and (max-width: 767px) {
    column-count: 2;
  }
  @media only screen and (max-width: 500px) {
    column-count: 1;
  }
`;
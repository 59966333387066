import React, { Component } from 'react';
import styled, {css} from 'styled-components';

class SectionHeading extends Component {
  render() {
    return (
      <h2 className={`${ this.props.className}`}>
        {this.props.heading}
      </h2>
    );
  }
}

export default styled(SectionHeading)`
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: var(--fw--reg);
  margin: ${props => props.marginSmall ? '0 0 55px' : '0 0 125px'};
  &:before {
    display: inline-block;
    width: 15px;
    height: 75px;
    content: '';
    margin: 0 20px 0 0;
    ${props => props.borderPurple && css`
      background: var(--accent-color--purple);
    `}
    ${props => props.borderRed && css`
      background: var(--accent-color--red);
    `}
    ${props => props.borderYellow && css`
      background: var(--accent-color--yellow);
    `}
  }
  @media only screen and (max-width: 767px) {
    font-size: 24px;
    margin: 0 0 65px;
  }
`;
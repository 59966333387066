import React, { Component } from 'react';
import styled from 'styled-components';

import Accordion from './components/Accordion';
import Container from './components/Container';
import ObjcLogo from './components/ObjcLogo';
import SectionBlurb from './components/SectionBlurb';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';
import TextBox from './components/TextBox';

import aboutMap from './assets/img/home__about--map.png';
import chrisEidhof from './assets/img/speakers/speakers__chris-eidhof.jpg';

const SectionWorkshop = styled.section`
  padding: 125px 0 115px;
  article {
    width: 85%;
    max-width: 775px;
    margin: 0 auto;
  }
  note {
    width: 85%;
    max-width: 775px;
	text-align: center;
    margin: 0 auto;
  }
  .section__blurb {
    margin: 0 0 85px;
    padding: 70px 65px;
    border: 3px solid var(--accent-color--yellow);
  }
  .logo {
    position: relative;
    width: 80%;
    max-width: 250px;
    margin: 0 auto -60px;
    padding: 0 25px;
    background: var(--brand-color);
  }
  @media only screen and (max-width: 520px) {
    .section__blurb {
      padding: 40px 30px;
    }
  }
`;

const SectionAbout = styled.section`
  background: var(--bg--dark) top left url(${aboutMap}) no-repeat;
  background-size: cover;
  padding: 125px 0 155px;
  h3 {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  a {
    color: var(--accent-color--red);
  }
  svg,
  img {
    display: block;
    margin: 35px 0;
  }
`;

const SectionVideos = styled.section`
  padding: 125px 0 155px;
  .section__blurb {
    max-width: 850px;
    text-align: center;
    margin: 0 auto 25px;
  }
  .video__container {
    margin: 85px 0 0;
    h3 {
      margin: 0 0 20px;
    }
    iframe {
      max-width: 100%;
      margin: 30px 0 0;
    }
    @media only screen and (min-width: 992px) {
      padding: 0 16px 32px;
    }
  }
  a {
    color: inherit;
    transition: color .5s;
    &:hover {
      color: var(--accent-color--purple);
    }
  }
`;

class Workshop extends Component {
  componentDidMount() {
    document.title = "NSNorth 2019 | Workshop";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main className={`${ this.props.className}`}>
        <SectionHero bgSpeakers heading="Workshop" />
        <SectionWorkshop>
          <Container>
            <article>
              <ObjcLogo />
              <SectionBlurb blurb="Workshop: Swift Patterns" />

			        <Accordion>
                <h3>When and Where?</h3>
                <p>Friday, April 26th from 9-4pm at the main conference venue, the St. James Theatre.</p>
              </Accordion>
              <Accordion>
                <h3>Who Should Attend?</h3>
                <p>This workshop is meant for developers who are comfortable with Swift and iOS. We expect that you're writing Swift on a daily basis and that you understand how to create classes, methods, and how to use arrays and dictionaries. </p>
                <p>A good understanding of object-oriented programming is helpful. However, we don't expect that you know all about functional programming, generics or value types.</p>
              </Accordion>
              <Accordion>
                <h3>What Will I Learn?</h3>
                <p>This will be a very hands-on advanced workshop where instructor Chris Eidhof, of <a href="https://www.objc.io/" target="_blank" rel="noopener noreferrer">objc.io</a>, will dive deep into what makes Swift truly unique in order to solve real-world problems:</p>
                <ul>
                  <li>Generics</li>
                  <li>Enums with Associated Values</li>
                  <li>Value Types</li>
                  <li>Higher-Order Functions</li>
                </ul>
                <p>Through his experience at <a href="https://www.objc.io/" target="_blank" rel="noopener noreferrer">objc.io</a>, Chris finds that the above features can help in almost any project to make your code smaller, simpler, and safer.</p>
              </Accordion>
              <Accordion>
                <h3>What is the format?</h3>
                <p>The workshop introduces each concept with a small presentation, and then continues by you solving exercises. After each round of exercises, we'll discuss the solutions together.</p>
              </Accordion>
            </article>
          </Container>
        </SectionWorkshop>
        <SectionAbout>
          <Container>
            <SectionHeading borderRed heading="About Chris & objc.io" />
            <TextBox boxSmall textBoxLeft borderRed>
              <h3>About objc.io:</h3>
              <ObjcLogo />
              <p><a href="https://www.objc.io/" target="_blank" rel="noopener noreferrer">objc.io</a> was founded in 2013 in Berlin, Germany. The team at objc.io previously published 24 monthly issues of our magazine on iOS and macOS development. They have published books on advanced topics in Swift and iOS development. Since 2016, they also host a weekly video series called Swift Talk.</p>
            </TextBox>
            <TextBox boxSmall textBoxRight borderRed>
              <h3>About Chris:</h3>
              <img src={chrisEidhof} alt="Portrait of Chris" />
              <p>Chris is the founder of <a href="https://www.objc.io/" target="_blank" rel="noopener noreferrer">objc.io</a> and co-authored the books Functional Swift, Advanced Swift and App Architecture. He is the host of Swift Talk, a weekly video series covering advanced topics in Swift.</p>
              <p>He also helped create the Mac apps Deckset and Scenery. Chris founded UIKonf in 2013, and the Functional Swift Conference in 2014.</p>
            </TextBox>
          </Container>
        </SectionAbout>
        <SectionVideos>
          <Container>
            <SectionHeading marginSmall borderPurple heading="Swift Talk Videos" />
            <p className="section__blurb">Here are a couple of sample videos to give you an idea of the type of discussion that will occur during the workshop. These are freely available from the <a href="https://www.objc.io/" target="_blank" rel="noopener noreferrer">objc.io</a> website, which is an excellent resource for Swift development.</p>
            <div className="grid">
              <div className="grid__col-md-6 grid__col-12 video__container">
                <h3>Structs and Mutation</h3>
                <p><a href="https://talk.objc.io/episodes/S01E21-structs-and-mutation" target="_blank" rel="noopener noreferrer">Swift Talk #21</a></p>
				<iframe src="https://player.vimeo.com/video/294546794" title="Structs and Mutation" width="560" height="315" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"></iframe>
              </div>
              <div className="grid__col-md-6 grid__col-12 video__container">
                <h3>Protocols & Class Hierarchies</h3>
                <p><a href="https://talk.objc.io/episodes/S01E29-protocols-class-hierarchies" target="_blank" rel="noopener noreferrer">Swift Talk #29</a></p>
				<iframe src="https://player.vimeo.com/video/294550613" title="Protocols & Class Hierarchies" width="560" height="315" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"></iframe>
              </div>
            </div>
          </Container>
        </SectionVideos>
      </main>
    );
  }
}

export default Workshop;
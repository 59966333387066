import React, { Component } from 'react';
import styled from 'styled-components';

class SectionBlurb extends Component {
  render() {
    return (
      <p className={`${ this.props.className + ' section__blurb'}`}>
        {this.props.blurb}
      </p>
    );
  }
}

export default styled(SectionBlurb)`
  font-size: 28px;
  line-height: 1.65;
  text-align: center;
  @media only screen and (max-width: 767px) {
    font-size: 22px;
  }
`;
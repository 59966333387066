import React, { Component } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import styled from 'styled-components';

import Container from './Container';
import MailchimpForm from './MailchimpForm';
import SocialMediaMenu from './SocialMediaMenu';

const FooterMenu = styled.ul`
  flex-direction: column;
  li {
    font-size: 20px;
    transition: all .5s;
    &:nth-child(3n+1) a:hover{
      color: var(--accent-color--yellow);
    }
    &:nth-child(3n+2) a:hover {
      color: var(--accent-color--red);
    }
    &:nth-child(3n+3) a:hover {
      color: var(--accent-color--purple);
    } 
    &:not(:last-child) {
      margin: 0 0 15px;
    }
  }
  a {
    color: inherit;
  }
`;

const FooterSocialMedia = styled.div`
  max-width: 500px;
  @media only screen and (max-width: 991px) {
    align-items: center;
    margin: 65px auto 0;
  }
  @media only screen and (min-width: 466px) {
    div {
      display: flex;
    }
  }
`;

const FooterCopy = styled.div`
  margin: 70px 0 0;
  padding: 35px 0;
  background: var(--bg--dark);
  address {
    font-style: normal;
    p:not(:last-child) {
      margin: 0 0 5px;
    }
    @media only screen and (min-width: 768px) {
      align-items: flex-end;
    }
    @media only screen and (max-width: 767px) {
      margin: 25px 0 0;
    }
  }
  p {
    font-size: 16px;
  }
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`;

const FooterPreviousYears = styled.ul`
  margin: 0 0 31px;
  li {
    &:not(:first-child) {
      padding: 0 10px;
      border-right: 1px solid var(--text-color);
    }
    &:last-child {
      border: 0;
    }
    &:nth-child(3n+2) a:hover{
      color: var(--accent-color--yellow);
    }
    &:nth-child(3n+3) a:hover {
      color: var(--accent-color--red);
    }
    &:nth-child(3n+4) a:hover {
      color: var(--accent-color--purple);
    }
  }
  a {
    color: inherit;
  }
  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
  @media only screen and (max-width: 400px) {
    li {
      margin: 0 0 10px;
    }
  }
`;

class Footer extends Component {
  render() {
    return (
      <footer className={`${ this.props.className}`}>
        <Container className="grid">
          <nav className="grid__col-md-6 grid__col-12">
            <FooterMenu className="grid">
              <li>
                <NavLink
                  scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                  to="/#about"
                >
                  What is NSNorth?
                </NavLink>
              </li>
              <li>
                <NavLink to="/videos">Videos</NavLink>
              </li>
              <li>
                <NavLink to="/photos">Photos</NavLink>
              </li>
              <li>
                <NavLink to="/podcasts">Podcasts</NavLink>
              </li>
              <li>
                <NavLink to="/speakers">Speakers</NavLink>
              </li>
            </FooterMenu>
            <FooterMenu className="grid">
              <li>
                <NavLink to="/venue">Venue</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact</NavLink>
              </li>
              <li>
                <NavLink
                  scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                  to="/#about"
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink to="/sponsors">Sponsors</NavLink>
              </li>
            </FooterMenu>
          </nav>
          <FooterSocialMedia className="grid__col-md-5 grid__col-12">
            <div>
              <SocialMediaMenu />
            </div>
          </FooterSocialMedia>
        </Container>
        <FooterCopy>
          <Container className="grid">
            <div className="grid__col-sm-7 grid__col-12">
              <FooterPreviousYears className="grid">
                <li>Previous Years:</li>
                <li>
                  <a href="https://2013.nsnorth.ca/" target="_blank" rel="noopener noreferrer">2013</a>
                </li>
                <li>
                  <a href="https://2014.nsnorth.ca/" target="_blank" rel="noopener noreferrer">2014</a>
                </li>
                <li>
                  <a href="https://2015.nsnorth.ca/" target="_blank" rel="noopener noreferrer">2015</a>
                </li>
                <li>
                  <a href="https://2016.nsnorth.ca/" target="_blank" rel="noopener noreferrer">2016</a>
                </li>
              </FooterPreviousYears>
              <p>Copyright 2013-2019 Sparked Insight</p>
            </div>
          </Container>
        </FooterCopy>
      </footer>
    );
  }
}

export default styled(Footer)`
  padding: 85px 0 0;
  background: var(--brand-color);
  .container {
    justify-content: space-between;
  }
  nav {
    flex-direction: row;
  }
`;
import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './components/Container';
import LocationGallery from './components/LocationGallery';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';

import location1 from './assets/img/location/location--1.jpg';
import location2 from './assets/img/location/location--12.jpg';
import location3 from './assets/img/location/location--13.jpg';

const SectionWhere = styled.section`
  padding: 115px 0 150px;
  a {
    color: var(--accent-color--red);
  }
  .grid {
    justify-content: space-between;
  }
  article {
    justify-content: center;
  }
  figure {
    justify-content: center;
    padding: 25px 0 0;
    @media only screen and (min-width: 768px) {
      align-items: flex-end;
      &:nth-child(odd) {
        padding-right: 12.5px;
      }
      &:nth-child(even) {
        padding-left: 12.5px;
      }
    }
    @media only screen and (max-width: 767px) {
      align-items: center;
    }
  }
  p {
    font-size: 22px;
    line-height: 2;
    margin: 0 0 30px;
  }
`;

class Venue extends Component {
  componentDidMount() {
    document.title = "NSNorth 2019 | Venue";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main className={`${ this.props.className}`}>
        <SectionHero bgVenue heading="Venue" />
        <SectionWhere>
          <Container>
            <SectionHeading borderYellow heading="Where was NSNorth?" />
            <div className="grid">
              <article className="grid__col-md-8 grid__col-sm-7 grid__col-12">
                <p>Our venue was the historic <a href="https://goo.gl/maps/PxC4zppE8wv" target="_blank" rel="noopener noreferrer">St. James Theatre</a>, which is the former home of the Canadian Imperial Bank of Commerce. Built over the course of two years from 1907 to 1909, the St. James Theatre’s main hall features beautiful marble and stone, stained glass windows, and 70’ high walls.</p>
                <p>The St. James is in the heart of Old Montreal, considered North America’s most European neighbourhood. Old Montreal is very walkable with fantastic cafés, restaurants, and shops to explore.</p>
              </article>
              <figure className="grid__col-sm-4 grid__col-12">
                <img src={location1} alt="Location" />
              </figure>
              <figure className="grid__col-sm-6 grid__col-12">
                <img src={location2} alt="Location" />
              </figure>
              <figure className="grid__col-sm-6 grid__col-12">
                <img src={location3} alt="Location" />
              </figure>
            </div>
            <LocationGallery />
          </Container>
        </SectionWhere>
      </main>
    );
  }
}

export default Venue;

import React, { Component } from 'react';
import styled from 'styled-components';

class Accordion extends Component {
  render() {
    return (
      <div className={`${ this.props.className}`}>
        {this.props.children}
      </div>
    )
  }
}

export default styled(Accordion)`
  padding: 35px 0;
  border-top: 3px solid var(--text-color);
  &:last-of-type {
    border-bottom: 3px solid var(--text-color);
  }
  h3 {
    font-size: 20px;
    outline: 0;
    margin: 0 0 25px;
  }
  p:not(:last-child),
  ul:not(:last-child) {
    margin: 0 0 25px;
  }
  ul {
    font-size: 16px;
    list-style: disc;
    li {
      margin: 0 0 15px 20px;
    }
  }
  p {
    font-size: 16px;
    a {
      color: var(--accent-color--yellow);
    }
  }
`;
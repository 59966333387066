import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './components/Container';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';

const SectionCode = styled.section`
  padding: 25px 0 175px;
  .container {
    padding: 85px 0 25px;
    &:not(:last-child) {
      border-bottom: 3px solid var(--text-color);
    }
  }
  a:hover {
    color: var(--accent-color--yellow);
  }
  p {
    max-width: 800px;
    margin: 0 auto 45px;
  }
`;

class Conduct extends Component {
  componentDidMount() {
    document.title = "NSNorth 2019 | Code of Conduct";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main className={`${ this.props.className}`}>
        <SectionHero bgSchedule heading="Code of Conduct" />
        <SectionCode>
          <Container>
            <p>NSNorth is a community conference intended for networking and collaboration in the iOS and Mac community.</p>
            <p>We value the participation of each person and want all attendees to have an enjoyable and fulfilling experience. Accordingly, all attendees are expected to show courtesy and respect to other attendees throughout the conference and at all conference events, whether officially sponsored by NSNorth or not.</p>
            <p>To make clear what is expected, all delegates, speakers, exhibitors and volunteers at any NSNorth event are required to conform to the following Code of Conduct. Organizers will enforce this code throughout the event.</p>
          </Container>
          <Container>
            <SectionHeading marginSmall borderYellow heading="The Short Version" />
            <p>NSNorth is dedicated to providing a harassment-free conference experience for everyone, regardless of gender, sexual orientation, disability, physical appearance, body size, race, religion, or choice of text editor. We do not tolerate harassment of conference participants in any form.</p>
		  	     <p>All communication should be appropriate for a professional audience including people of many different backgrounds. Sexual language and imagery is not appropriate for any conference venue, including talks.</p>
			      <p>Be kind to others. Do not insult or put down other attendees. Behave professionally. Remember that harassment and sexist, racist, or exclusionary jokes are not appropriate for NSNorth.</p>
            <p>Attendees violating these rules may be asked to leave the conference without a refund at the sole discretion of the conference organizers.</p>
            <p>Thank you for helping make this a welcoming, friendly event for all.</p>
          </Container>
          <Container>
            <SectionHeading marginSmall borderRed heading="The Longer Version" />
            <p>Harassment includes offensive verbal comments related to gender, sexual orientation, disability, physical appearance, body size, race, religion, sexual images in public spaces, deliberate intimidation, stalking, following, harassing photography or recording, sustained disruption of talks or other events, inappropriate physical contact, and unwelcome sexual attention.</p>
            <p>Participants asked to stop any harassing behaviour are expected to comply immediately.</p>
            <p>Exhibitors, sponsors or vendor booths, if any, are also subject to the anti-harassment policy. In particular, exhibitors should not use sexualized images, activities, or other material. Booth staff (including volunteers) should not use sexualized clothing/uniforms/costumes, or otherwise create a sexualized environment.</p>
            <p>Be careful in the words that you choose. Remember that sexist, racist, and other exclusionary jokes can be offensive to those around you.</p>
            <p>If a participant engages in behaviour that violates this code of conduct, the conference organizers may take any action they deem appropriate, including warning the offender or expulsion from the conference with no refund.</p>
          </Container>
          <Container>
            <SectionHeading marginSmall borderPurple heading="Contact Information" />
            <p>If you are being harassed, notice that someone else is being harassed, or have any other concerns, please contact any easily-identified conference volunteer, or either of the organizers: Dan or Philippe.</p>
            <p>Detailed contact information will be provided at the conference, and will be found on the back of your conference pass.</p>
            <p>Conference volunteers will be happy to help participants contact hotel/venue security or local law enforcement, provide escorts, or otherwise assist those experiencing harassment to feel safe for the duration of the conference. We value your attendance.</p>
          </Container>
          <Container>
            <SectionHeading marginSmall borderYellow heading="Attribution" />
            <p>This anti-harassment policy is based on the example policy from the Geek Feminism wiki, created by the Ada Initiative and other volunteers. It is used under the terms of the Creative Commons Zero license.</p>
          </Container>
        </SectionCode>
      </main>
    );
  }
}

export default Conduct;
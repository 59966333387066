import React, { Component } from 'react';
import styled from 'styled-components';

import logo from '../assets/img/logo.png';

class Logo extends Component {
  render() {
    return (
      <h1 className={this.props.className}>
        <a href="/">
          <img src={logo} alt="NSNorth Logo" />
        </a>
      </h1>
    );
  }
}

export default styled(Logo)`
  @media only screen and (max-width: 767px) {
    img {
      max-height: 65px;
    }
  }
`;
import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './components/Container';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';

const SectionVideos = styled.section`
  padding: 115px 0 35px;
  .container {
    margin: 0 auto 95px;
  }
  iframe {
    margin: 0 16px 32px;
  }
`;

class Videos extends Component {
  componentDidMount() {
    document.title = "NSNorth 2019 | Videos";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main className={`${ this.props.className}`}>
        <SectionHero bgSpeakers heading="Videos" />
        <SectionVideos>
          <Container>
            <SectionHeading marginSmall borderYellow heading="2019 Videos" />
            <iframe title="Valuing the Time You Have" src="https://player.vimeo.com/video/471004767" width="640" height="360" frameBorder="0" allow="fullscreen" allowFullScreen></iframe>
          </Container>
          <Container>
            <SectionHeading marginSmall borderYellow heading="2016 Videos" />
            <iframe title="Keynote, A Deeper Way To Build" src="https://player.vimeo.com/video/329296028" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="Carving out a Space in the Continuum of App Store Success" src="https://player.vimeo.com/video/329254922" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="Keynote Karaoke" src="https://player.vimeo.com/video/167109419" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
          </Container>
          <Container>
            <SectionHeading marginSmall borderRed heading="2015 Videos" />
            <iframe title="Fear is the Mind Killer" src="https://player.vimeo.com/video/128910281" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="Kids in the Driver's Seat" src="https://player.vimeo.com/video/128910280" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="The Everywhere App" src="https://player.vimeo.com/video/129102716" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="Isn't It Obvious?" src="https://player.vimeo.com/video/129387744" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="The Microsoft Band SDK" src="https://player.vimeo.com/video/129815372" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="The Coding Contrarian" src="https://player.vimeo.com/video/129820525" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="Humanities x Technology" src="https://player.vimeo.com/video/130100423" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="Saying Yes to Nodes" src="https://player.vimeo.com/video/131543934" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="How I Learned to Stop Worrying" src="https://player.vimeo.com/video/132657092" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
          </Container>
          <Container>
            <SectionHeading marginSmall borderPurple heading="2014 Videos" />
            <iframe title="A Decade at the Fruit Company" src="https://player.vimeo.com/video/110571205" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="Building in Success" src="https://player.vimeo.com/video/110182006" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="42" src="https://player.vimeo.com/video/118560648" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="Code Style Tools for Objective-C" src="https://player.vimeo.com/video/120527528" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
          </Container>
          <Container>
            <SectionHeading marginSmall borderYellow heading="2013 Videos" />
            <iframe title="Indie++" src="https://player.vimeo.com/video/111550887" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="An Educated Guess" src="https://player.vimeo.com/video/112810234" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
            <iframe title="Working With Designers" src="https://player.vimeo.com/video/112987565" width="640" height="360" frameBorder="0"  allowFullScreen></iframe>
          </Container>
        </SectionVideos>
      </main>
    );
  }
}

export default Videos;
import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

import Container from './Container';
import SectionHeading from './SectionHeading';

import photos from './photos.json';

const options = {
  buttons: {
    showAutoplayButton: true,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: true,
    size: '40px'
  }
}

class PictureGallery extends Component {
  render() {
    return (
      <div className={this.props.className}>
        {photos.map((gallery, i) => (
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <Container key={i}>
                <SectionHeading marginSmall borderPurple heading={`${gallery.year} Photos`} />
                <div className="grid">
                    {gallery.images.map((photo, i) => (
                      <figure key={i} className="grid__col-md-4 grid__col-sm-6 grid__col-12">
                        <LazyLoad throttle={500} height={285}>
                          <a href={require(`../assets/img/photos/${gallery.year}/photo--lg--${photo.number}.jpg`).default} data-attribute="SRL">
                          	<img src={require(`../assets/img/photos/${gallery.year}/photo--sm--${photo.number}.jpg`).default} alt={photo.alt} title={photo.alt} />
                          </a>
                        </LazyLoad>
                      </figure>
                    ))}
                </div>
              </Container>
            </SRLWrapper>
          </SimpleReactLightbox>
        ))}

      </div>
    );
  }
}

export default styled(PictureGallery)`
  margin: 75px 0 0;
  .container {
    margin: 0 auto 125px;
  }
  figure {
    padding: 12.5px;
    img:hover {
      cursor: pointer;
    }
  }
`;
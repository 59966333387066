import React, { Component } from 'react';
import styled from 'styled-components';
import iconTwitter from '../assets/img/icons/icon--twitter.svg';

class BlitzTalkRow extends Component {
  render() {
    return (
      <ul className={`${ this.props.className + ' grid'}`}>
        {this.props.speakers.map((speaker, i) => (
          <li key={i} className="grid__col-md-3 grid__col-sm-6 grid__col-12">
            <h3>{speaker.name}</h3>
            {speaker.social ?
              <p>
                <a href={speaker.social} target="_blank" rel="noopener noreferrer">
                  <img src={iconTwitter} alt="Icon Twitter" />
                </a>
              </p>
            : null}
            <p>{speaker.title}</p>
          </li>
        ))}
      </ul>
    );
  }
}

export default styled(BlitzTalkRow)`
  margin: 0 0 45px;
  @media only screen and (max-width: 1200px) {
    &:before {
      height: 30%;
    }
  }
  li {
    align-items: flex-start;
    &:after {
      width: 95px;
      height: 5px;
      content: '';
      margin: 30px 0 0;
      background: ${'var(--accent-color--purple)'};
    }
    @media only screen and (max-width: 991px) {
      margin: 0 0 75px;
    }
    @media only screen and (min-width: 768px) {
      padding: 0 30px;
    }
  }
  h3 {
    font-size: 25px;
    margin: 65px 0 30px;
    @media only screen and (max-width: 991px) {
      margin: 35px 0 30px;
    }
  }
  p {
    max-width: 230px;
    font-size: 18px;
  }
  @media only screen and (max-width: 991px) {
    img {
      max-width: 200px;
    }
  }
`;
import React, { Component } from 'react';
import styled from 'styled-components';

class SpeakersRow extends Component {
  render() {
    return (
      <ul className={`${ this.props.className + ' grid'}`}>
        {this.props.speakers.map((speaker, i) => (
          <li key={i} className="grid__col-md-3 grid__col-sm-6 grid__col-12">
            <a href={"speakers#" + speaker.ref}><img src={require(`../assets/img/speakers/speakers__${speaker.img}`).default} alt={speaker.name} /></a>
            <h3>{speaker.name}</h3>
            <p>{speaker.title}</p>
          </li>
        ))}
      </ul>
    );
  }
}

export default styled(SpeakersRow)`
  margin: 0 0 45px;
  @media only screen and (min-width: 992px) {
    margin: 0 0 120px;
    padding: 65px 20px 0;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 38%;
      content: '';
      border: ${props => props.homePage ? '3px solid var(--accent-color--purple)' : '3px solid var(--accent-color--yellow)'};
    }
  }
  @media only screen and (max-width: 1200px) {
    &:before {
      height: 30%;
    }
  }
  li {
    align-items: flex-start;
    &:after {
      width: 95px;
      height: 5px;
      content: '';
      margin: 30px 0 0;
      background: ${props => props.homePage ? 'var(--accent-color--purple)' : 'var(--accent-color--yellow)'};
    }
    @media only screen and (max-width: 991px) {
      margin: 0 0 75px;
    }
    @media only screen and (min-width: 768px) {
      padding: 0 30px;
    }
  }
  h3 {
    font-size: 25px;
    margin: 65px 0 30px;
    @media only screen and (max-width: 991px) {
      margin: 35px 0 30px;
    }
  }
  p {
    max-width: 230px;
    font-size: 18px;
  }
  @media only screen and (max-width: 991px) {
    img {
      max-width: 200px;
    }
  }
`;
import React, { Component } from 'react';
import styled from 'styled-components';

import Button from './components/Button';
import Container from './components/Container';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';
import podcasts from './components/podcasts.json';

const SectionPodcasts = styled.section`
  padding: 25px 0 55px;
  .container {
    padding: 85px 0 25px;
    &:not(:last-child) {
      border-bottom: 3px solid var(--text-color);
    }
  }
  .btn {
    margin: 0 auto 125px;
  }
`;

const PodcastContainer = styled.div`
  justify-content: space-around;
  article {
    margin: 0 0 75px;
    &:nth-of-type(3n+1) {
      border: 1px solid var(--accent-color--yellow);
      h3 {
        background: var(--accent-color--yellow);
      }
    }
    &:nth-of-type(3n+2) {
      border: 1px solid var(--accent-color--red);
      h3 {
        background: var(--accent-color--red);
      }
    }
    &:nth-of-type(3n+3) {
      border: 1px solid var(--accent-color--purple);
      h3 {
        background: var(--accent-color--purple);
      }
    }
  }
  h3 {
    text-align: center;
    padding: 35px 45px;
  }
  p {
    padding: 35px 45px;
  }
  a {
    display: block;
    height: 100%;
    color: inherit;
    transition: background .35s;
    &:hover {
      background: rgba(255, 255, 255, .1);
    }
  }
`;

class Podcasts extends Component {
  componentDidMount() {
    document.title = "NSNorth 2019 | Podcasts";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main className={`${ this.props.className}`}>
        <SectionHero bgSchedule heading="Podcasts" />
        <SectionPodcasts>
          <Container>
            <SectionHeading marginSmall borderRed heading="NSNorth Podcast" />
            <Button btnCTA url="https://itunes.apple.com/ca/podcast/nsnorth/id599959952?mt=2" text="Subscribe to the NSNorth Podcast" target="_blank" />
            <PodcastContainer className="grid">
            {podcasts.map((podcast, i) => (
              <article key={i} className="grid__col-md-5 grid__col-12">
                <a href={podcast.url} target="_blank" rel="noopener noreferrer"> 
                  <h3>{podcast.title}</h3>
                  <p>{podcast.description ? podcast.description : null}</p>
                </a>
              </article>
            ))}
            </PodcastContainer>
          </Container>
        </SectionPodcasts>
      </main>
    );
  }
}

export default Podcasts;
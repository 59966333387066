import React, { Component } from 'react';
import styled, {css} from 'styled-components';

import scheduleMap from '../assets/img/schedule__map.png';
import speakersMap from '../assets/img/speakers__map.png';
import venueMap from '../assets/img/venue__map.png';

class SectionHero extends Component {
  render() {
    return (
      <section className={`${ this.props.className}`}>
        <h2>{this.props.heading}</h2>
      </section>
    );
  }
}

export default styled(SectionHero)`
  display: flex;
  height: 350px;
  align-items: center;
  justify-content: center;
  padding: 120px 7.5% 0;
  background-color: var(--bg--dark);
  background-size: cover;
  background-repeat: no-repeat;
  h2 {
    font-size: 45px;
    font-weight: var(--fw--med);
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    height: 435px;
    h2 {
      font-size: 35px;
    }
  }
  ${props => props.bgSchedule && css`
    background-image: url(${scheduleMap});
  `}
  ${props => props.bgSpeakers && css`
    background-image: url(${speakersMap});
  `}
  ${props => props.bgVenue && css`
    background-image: url(${venueMap});
  `}  
`;
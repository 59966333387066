import React, { Component } from 'react';
import styled from 'styled-components';

import Button from './components/Button';
import Container from './components/Container';
import SectionBlurb from './components/SectionBlurb';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';
import TextBox from './components/TextBox';

import map from './assets/img/home__conduct--map.png';
import logoSponsor1 from './assets/img/logos/logo--1password.png';
import logoSponsor2 from './assets/img/logos/logo--medium.svg';
import logoSponsor3 from './assets/img/logos/logo--manning-publications.svg';
import logoSponsor4 from './assets/img/logos/logo--lightspeed.png';


const SectionSponsor = styled.section`
  padding: 115px 0 175px;
  img {
    display: block
    width: 100%;
    max-width: 300px;
    margin: 0 auto 45px;
  }
  p:not(:last-child) {
    margin: 0 0 25px;
  }
  &:nth-child(odd) {
    background: var(--bg--dark) top left url(${map}) no-repeat;
    background-size: cover;
  }
`;



class Sponsors extends Component {
  componentDidMount() {
    document.title = "NSNorth 2019 | Sponsors";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main className={`${ this.props.className}`}>
        <SectionHero bgSchedule heading="Sponsors" />
        <SectionSponsor>
          <Container>
            <SectionHeading borderYellow heading="Become a Sponsor" />
            <TextBox borderYellow boxSmall textLarge>
              <SectionBlurb blurb="NSNorth wouldn't be possible without the generous support of our sponsors. Please get in touch if you would like to learn more about our available sponsorships." />
            </TextBox>
            <Button bgYellow url="mailto:dan@nsnorth.ca" btnTextBox text="Get in touch" />
          </Container>
        </SectionSponsor>
        <SectionSponsor>
          <Container>
            <SectionHeading borderRed heading="Silver sponsor" />
            <TextBox borderRed>
              <a href="https://1password.com/" target="_blank" rel="noopener noreferrer" >
                <img src={logoSponsor1} alt="1Password" />
              </a>
              <p>1Password is a password manager that keeps you, your family, and your business safe online. It stores all your personal information — passwords, credit cards, licenses, and more — behind a single Master Password that only you know. Sign into apps and websites with a single click, and guard against data breaches with the Watchtower suite of security tools. 1Password is available for Mac, Windows, iOS, Android, and Linux.</p>
            </TextBox>
          </Container>
        </SectionSponsor>
        <SectionSponsor>
          <Container>
            <SectionHeading borderPurple heading="Bronze and Lunch sponsor" />
            <TextBox borderPurple>
              <a href="https://medium.com/" target="_blank" rel="noopener noreferrer" >
                <img src={logoSponsor2} alt="Medium" />
              </a>
              <p>Medium is an online publishing platform where words matter. Our mission is to deepen readers' understanding of the world and to empower writers to share their best work and biggest ideas. The Medium iOS app provides the best place to find quality stories and have a clean reading experience wherever you are.</p>
            </TextBox>
          </Container>
        </SectionSponsor>
        <SectionSponsor>
          <Container>
            <SectionHeading borderYellow heading="Diversity Sponsor" />
            <TextBox borderYellow>
              <a href="https://lightspeedhq.com/" target="_blank" rel="noopener noreferrer" >
                <img src={logoSponsor4} alt="Lightspeed" />
              </a>
              <p>Lightspeed is about bringing cities and communities to life by powering SMBs. We believe that commerce belongs to everyone. From day 1 it’s been about much more than transactions; we set out to help entrepreneurs work smarter, make data-driven decisions, and create the best possible experience for their customers. That philosophy still runs through everything that we do. We are a company infused with culture, just as much as code. Our values are what unite us, but our differences are what inspire us. We get to do the best work of our lives and we celebrate our successes every chance we get.</p>
            </TextBox>
          </Container>
        </SectionSponsor>
      </main>
    );
  }
}

export default Sponsors;
import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './components/Container';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';
import SpeakersRow from './components/SpeakersRow';
import BlitzTalkRow from './components/BlitzTalkRow';
import SectionBlurb from './components/SectionBlurb';

import iconEmail from './assets/img/icons/icon--email.svg';
import iconGithub from './assets/img/icons/icon--github.svg';
import iconTwitter from './assets/img/icons/icon--twitter.svg';
import iconWebsite from './assets/img/icons/icon--website.svg';
import speakers from './components/speakers.json';
import speakersMap from './assets/img/speakers__map.png';

import blitzTalkList from './components/blitz-talks.json';
import speakersList from './components/previous-speakers.json';


const SectionSpeakers = styled.section`
  padding: 125px 0 155px;
  article,
  article {
    padding: 85px 0;
    border-top: 3px solid var(--text-color);
    figure {
      align-items: flex-start;
      &:before {
        position: absolute;
        top: 15px;
        left: 15px;
        width: 265px;
        height: 265px;
        content: '';
        border: 3px solid var(--text-color);
      }
    }
    img {
      position: relative;
      max-width: 265px;
    }
    h3 {
      font-size: 28px;
    }
    h4 {
      font-size: 18px;
      font-weight: var(--fw--reg);
      line-height: 1.5;
      @media only screen and (max-width: 767px) and (min-width: 491px) {
        max-width: calc(100% - 200px);
      }
    }
    &:nth-child(3n+2) {
      figure:before {
        border-color: var(--accent-color--yellow);
      }
      .speaker__contact-info:after {
        background: var(--accent-color--yellow);
      }
    }
    &:nth-child(3n+3) {
      figure:before {
        border-color: var(--accent-color--red);
      }
      .speaker__contact-info:after {
        background: var(--accent-color--red);
      }
    }
    &:nth-child(3n+4) {
      figure:before {
        border-color: var(--accent-color--purple);
      }
      .speaker__contact-info:after {
        background: var(--accent-color--purple);
      }
    }
    @media only screen and (max-width: 991px) {
      padding: 55px 0;
      figure {
        margin: 0 0 70px;
        &:before {
          height: 200px;
        }
      }
      figure:before,
      img {
        max-width: 200px;
      }
      h3 {
        fonts-ize: 24px;
      }
    }
  }
`;

const SectionIntro = styled.p`
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 2.2;
  margin: 0 0 120px;
  @media only screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 2;
    margin: 0 0 75px;
  }
`;

const SectionIntroSmall = styled.p`
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 0 0 120px;
  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 1.3;
    margin: 0 0 75px;
  }
`;

const SectionOutro = styled.p`
  font-size: 26px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 2.2;
  margin: 0 0 120px;
  @media only screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 2;
    margin: 0px;
  }
`;

const SpeakerContactInfo = styled.div`
  justify-content: space-between;
  margin: 15px 0 45px;
  padding: 0 0 30px;
  ul {
    display: flex;
    max-width: 185px;
    flex-direction: row;
    justify-content: flex-end;
    li {
      margin: 0 13px;
      img {
        max-width: 30px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @media only screen and (max-width: 490px) {
      max-width: 100%;
      justify-content: flex-start;
      margin: 25px 0 0;
      li:first-child {
        margin-left: 0;
      }
    }
  }
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 95px;
    height: 5px;
    content: '';
  }
`;

const SessionInfo = styled.div`
	margin-top: -16px;
`;

const SectionBlitzTalks = styled.section`
  background: var(--bg--dark) top left url(${speakersMap}) no-repeat;
  background-size: cover;
  padding: 115px 0 55px;
`;

const SectionPreviousSpeakers = styled.section`
  background: var(--bg--dark) top left url(${speakersMap}) no-repeat;
  background-size: cover;
  padding: 115px 0 55px;
`;

class Speakers extends Component {
  componentDidMount() {
    document.title = "NSNorth 2019 | Speakers";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main className={`${ this.props.className}`}>
        <SectionHero bgSpeakers heading="Speakers" />
        <SectionSpeakers>
          <Container>
            <SectionIntro>Our focus at NSNorth has always been on getting a diverse range of speakers who come from a wide variety of backgrounds. You will hear from from independents and consultants; from developers and designers; from business leaders and thought leaders.</SectionIntro>
            {speakers.map((speaker, i) => (
              <article key={i} className="grid" id={speaker.ref}>
                <figure className="grid__col-lg-4 grid__col-md-5 grid__col-12">
                  <img src={require(`./assets/img/speakers/speakers__${speaker.img}`).default} alt={speaker.name} />
                </figure>
                <div className="grid__col-lg-8 grid__col-md-7 grid__col-">
                  <h3>{speaker.name}</h3>
                  <SpeakerContactInfo className="grid speaker__contact-info">
                    <h4 className="grid__col-sm-auto grid__col-12">{speaker.title}</h4>
                    <ul className="grid__col-sm-auto grid__col-12">
                      {speaker.urlGithub ?
                        <li>
                          <a href={speaker.urlGithub} target="_blank" rel="noopener noreferrer">
                            <img src={iconGithub} alt="Icon Github" />
                          </a>
                        </li>
                      : null}
                      {speaker.urlTwitter ?
                        <li>
                          <a href={speaker.urlTwitter} target="_blank" rel="noopener noreferrer">
                            <img src={iconTwitter} alt="Icon Twitter" />
                          </a>
                        </li>
                      : null}
                      {speaker.urlWebsite ?
                        <li>
                          <a href={speaker.urlWebsite} target="_blank" rel="noopener noreferrer">
                            <img src={iconWebsite} alt="Icon Website" />
                          </a>
                        </li>
                      : null}
                      {speaker.email ?
                        <li>
                          <a href={`${ 'mailto:' + speaker.email}`}>
                            <img src={iconEmail} alt="Icon Email" />
                          </a>
                        </li>
                      : null}
                    </ul>
                  </SpeakerContactInfo>
                    {speaker.keynote ? 
                    <SessionInfo>
                    <p><i>Keynote: {speaker.keynote}</i><br/><br/></p>
                    </SessionInfo>
                    : null}
                    {speaker.topic ? 
                    <SessionInfo>
                    <p><i>Topic: {speaker.topic}</i><br/><br/></p>
                    </SessionInfo>
                    : null}
                    {speaker.description.map((p, i) =>
                    <p key={i} dangerouslySetInnerHTML={ { __html: p } }></p>
					)}
					{speaker.news ?
						<div><br/><SectionBlurb blurb={speaker.news}/></div>
					: null}
                </div>
              </article>
            ))}
          </Container>
        </SectionSpeakers>
        <SectionBlitzTalks>
          <Container>
            <SectionHeading borderPurple heading="Blitz Talks" />
            <SectionIntroSmall>During the conference we will have nine Blitz Talks, also known as Lightning Talks. These are very short presentations where our attendees present on a topic of their choice. Our presenters for this year will be:</SectionIntroSmall>
            <BlitzTalkRow speakers={blitzTalkList} />
          </Container>
        </SectionBlitzTalks>
        <SectionPreviousSpeakers>
          <Container>
            <SectionHeading borderYellow heading="Past Speakers" />
            <SpeakersRow speakers={speakersList} />
          </Container>
        </SectionPreviousSpeakers>
      </main>
    );
  }
}

export default Speakers;
import React, { Component } from 'react';
import styled from 'styled-components';

import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

import Button from './components/Button';
import Container from './components/Container';
import SectionBlurb from './components/SectionBlurb';
import SectionHeading from './components/SectionHeading';
import SpeakersRow from './components/SpeakersRow';

import aboutMap from './assets/img/home__about--map.png';
import aboutGroup from './assets/img/home/home__about--groups.jpg';
import conductMap from './assets/img/home__conduct--map.png';
import speakerKenKocienda from './assets/img/speakers/speakers__ken-kocienda.jpg';
import speakerKateONeill from './assets/img/speakers/speakers__kate-oneill.jpg';
import logoSponsor1 from './assets/img/logos/logo--1password.png';
import logoSponsor2 from './assets/img/logos/logo--medium.svg';
import logoSponsor4 from './assets/img/logos/logo--lightspeed.png';
import podcast1 from './assets/img/home/podcast--1.jpg';
import podcast2 from './assets/img/home/podcast--2.jpg';
import podcast3 from './assets/img/home/podcast--3.jpg';
import podcastBg from './assets/img/home/podcasts__bg.png';

import speakers from './components/speakers.json';

const SectionHero = styled.section`
  background: var(--bg--dark) url(${conductMap}) no-repeat;
  background-size: cover;
  padding: 165px 0 95px;
  iframe {
    height: 250px;
    padding: 0 12px;
    &:nth-of-type(2) {
      padding-left: 0;
    }
    &:nth-of-type(4) {
      padding-right: 0;
    }
    @media only screen and (max-width: 991px) {
      margin: 0 0 24px;
      padding: 0;
    }
  }
  .video--featured {
    height: 50vh;
    min-height: 450px;
    margin: 0 0 24px;
    padding: 0;
  }
  .btn {
    margin: 60px auto 0;
  }
`;

const Heading = styled.h2`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 335px;
  font-size: 40px;
  font-weight: var(--fw--med);
  background: var(--accent-color--yellow);
  padding: 15px 25px;
  border-right: 25px solid var(--accent-color--red);
  &:before {
    position: absolute;
    top: 0;
    right: -50px;
    width: 25px;
    height: 100%;
    content: '';
    background: var(--accent-color--purple);
  }
  @media only screen and (max-width: 425px) {
    max-width: calc(100% - 25px);
  }
  @media only screen and (max-width: 350px) {
    font-size: 25px;
  }
`;

const SectionPhotos = styled.section`
  position: relative;
  padding: 85px 0 105px;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: .03;
  }
  h2,
  .btn {
    position: relative;
  }
  .btn {
    margin: 55px auto 0;
  }
`;

const FeaturedPhoto = styled.figure`
  overflow: hidden;
  max-height: 210px;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px;
  padding: 0 10px;
  @media only screen and (max-width: 1590px) and (min-width: 1201px) {
    max-height: 156px;
  }
  @media only screen and (max-width: 1200px) and (min-width: 991px) {
    max-height: 127px;
  }
  @media only screen and (min-width: 991px) {
    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(9) {
      width: 25%;
    }
    &:nth-child(2),
    &:nth-child(6),
    &:nth-child(7) {
      width: 33.33333333%;
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(8) {
      width: 41.66666667%;
    }
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    max-height: 168px;
    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(8),
    &:nth-child(9) {
      width: 41.66666667%;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(6),
    &:nth-child(7) {
      width: 58.33333333%;
    }
  }
`;

const SectionPodcasts = styled.section`
  background: var(--bg--dark) bottom right url(${podcastBg}) no-repeat;
  background-size: 100% auto;
  padding: 100px 0 165px;
  .container {
    align-items: center;
    justify-content: space-between;
  }
  article {
    @media only screen and (max-width: 991px) {
      h2 {
        margin: 0 0 55px;
      }
    }
  }
  ul {
    .podcast--1 {
      margin: 0 0 0 auto;
    }
    .podcast--2 {
      z-index: 1;
      margin: -195px 0 -50px;
    }
    .podcast--3 {
      margin: 0 auto;
    }
    @media only screen and (max-width: 1400px) {
      .podcast--1 {
        max-width: 70%;
      }
      .podcast--2 {
        max-width: 75%;
        margin: -25% 0 -7%;
      }
      .podcast--3 {
        max-width: 65%;
      } 
    }
  }
  .btn {
    margin: 0;
    @media only screen and (max-width: 991px) {
      margin: 0 auto 95px;
    }
  }
`;

const SectionAbout = styled.section`
  padding: 125px 0 155px;
  article {
    width: 85%;
    max-width: 775px;
    margin: 0 auto;
  }
  .section__blurb {
    padding: 70px 65px 150px;
    border: 3px solid var(--accent-color--yellow);
  }
  img {
    display: block;
    width: 90%;
    margin: -130px auto 75px;
  }
  p {
    margin: 0 0 45px;
  }
  ul {
    font-size: 20px;
    list-style: disc;
    margin: 0 0 0 45px;
    li {
      margin: 0 0 15px;
    }
  }
  .btn {
    margin: 95px auto 0;
  }
  @media only screen and (max-width: 500px) {
    .section__blurb {
      padding: 40px 30px 90px;
    }
    .btn {
      margin: 85px auto 0;
    }
  }
  @media only screen and (max-width: 375px) {
    img {
      margin: -100px auto 65px;
    }
  }
`;

const SectionSpeakers = styled.section`
  background: var(--bg--dark) top left url(${aboutMap}) no-repeat;
  background-size: cover;
  padding: 125px 0 155px;
  .section__sub-heading {
    font-size: 28px;
    font-weight: 400;
    margin: -35px auto 75px;
    @media only screen and (min-width: 768px) {
      text-align: center;
    }
    @media only screen and (max-width: 767px) {
      font-size: 24px;
      margin: 0 0 55px;
    }
  }
`;

const SpeakersFeatured = styled.div`
  max-width: 1000px;
  margin: 0 auto 125px;
  padding: 0 45px;
  figure {
    display: flex;
    align-items: center;
  }
  h3 {
    font-size: 25px;
    margin: 35px 0 20px;
  }
  p {
    font-size: 18px;
    line-height: 1.5;
    margin: 0 0 15px;
  }
  @media only screen and (max-width: 991px) {
    padding: 0 25px;
    p {
      max-width: 90%;
    }
    img {
      max-width: 200px;
    }
  }
  @media only screen and (min-width: 768px) {
    &:nth-of-type(odd) {
      border-right: 1px solid var(--text-color);
    }
    figure {
      justify-content: center;
    }
    h3 {
      text-align: center;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 0;
    &:nth-of-type(odd) {
      margin: 0 auto 45px;
      padding: 0 0 25px;
      border-bottom: 1px solid var(--text-color);
    }
    h3 {
      font-size: 25px;
    }
  }
`;

const SectionSponsors = styled.section`
  background: var(--bg--dark) url(${aboutMap}) no-repeat;
  background-size: cover;
  padding: 125px 0 155px;
  .sponsors__list {
    max-width: 900px;
    margin: 95px auto 135px;
    li {
      align-items: center;
      justify-content: center;
      margin: 0px 0px 50px;
      a {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        transition: opacity .35s;
        &:hover {
          opacity: .6;
        }
      }
      img {
        max-width: 90%;
      }
      img.s {
        max-width: 65%;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    padding: 125px 0 65px;
    .sponsors__list li {
      align-items: center;
      margin: 0 0 65px; 
    }
  }
`;

class Home extends Component {
  constructor() {
    super();

    this.state = {
      'featuredPhotos': []
    }
  }

  componentDidMount() {
    document.title = "NSNorth 2019 | Home";
    window.scrollTo(0, 0);

    this.getPhotos();
  }

  getPhotos() {
    fetch('photos.php?n=8')
      .then(results => results.json())
      .then(results => this.setState({'featuredPhotos': results}));
  }

  render() {
    const speakersList = speakers.slice(2, 6);

	const options = {
	  buttons: {
	    showAutoplayButton: true,
	    showCloseButton: true,
	    showDownloadButton: false,
	    showFullscreenButton: false,
	    showNextButton: true,
	    showPrevButton: true,
	    showThumbnailsButton: true,
	    size: '40px'
	  }
	}

    return (
      <main className={`${ this.props.className}`}>
        <SectionHero>
          <Container className="grid">
            <Heading className="grid__col-12">NSNorth 2019</Heading>
            <iframe className="grid__col-12 video--featured" title="Valuing the Time You Have" src="https://player.vimeo.com/video/471004767" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            <Button bgYellow url="/videos" text="View Videos" />
          </Container>
        </SectionHero>
        <SectionPhotos>
          <Container>
            <SectionHeading borderRed heading="Photos" marginSmall />
            <SimpleReactLightbox>
              <SRLWrapper options={options}>
                <div className="grid">
                  {this.state.featuredPhotos.map((photo, i) => (
                    <FeaturedPhoto key={i}>
                      <a href={`static/media/${photo.largeName}`} data-attribute="SRL">
	                      <img src={`static/media/${photo.smallName}`} alt={photo.description} title={photo.description} />
                      </a>
                    </FeaturedPhoto>
                  ))}
                </div>
              </SRLWrapper>
            </SimpleReactLightbox>
            <Button bgRed url="/photos" text="View Photos" />
          </Container>
        </SectionPhotos>
        <SectionPodcasts>
          <Container className="grid">
            <article className="grid__col-md-4">
              <SectionHeading borderPurple heading="Podcasts" />
              <Button bgPurple url="/podcasts" text="View Podcasts" />
            </article>
            <ul className="grid__col-md-7">
              <li className="podcast--1">
                <img src={podcast2} alt="Episode 61: Huda Idrees" />
              </li>
              <li className="podcast--2">
                <img src={podcast1} alt="Episode 62: Kate O'Neill" />
              </li>
              <li className="podcast--3">
                <img src={podcast3} alt="Episode 63: Rene Ritchie" />
              </li>
            </ul>
          </Container>
        </SectionPodcasts>
        <SectionAbout id="about">
          <Container>
            <SectionHeading borderYellow heading="What is NSNorth?" />
            <article>
              <SectionBlurb blurb="NSNorth is Canada’s premier independent Apple developer and designer conference." />
              <img src={aboutGroup} alt="NSNorth Through the Years" />
              <p>It is a three day, single-track event that brings together developers, designers, and business leaders to explore new ideas, concepts, technologies, and develop new and existing friendships.</p>
              <p>NSNorth can be thought of as a “boutique conference”, smaller in scale, but providing highly specialized, creative topics.</p>
              <ul>
                <li>Every edition of NSNorth has a major focus on networking. You will enjoy lots of time with friends new and old.</li>
                <li>Lots of healthy food and drink will be available throughout the event. You will not leave hungry.</li>
                <li>Your well-being and safety are priority #1 and there will always organizers and volunteers on staff to help you with whatever you may need</li>
              </ul>
            </article>
          </Container>
        </SectionAbout> 
        <SectionSpeakers>
          <Container>
            <SectionHeading borderPurple heading="2019 Speakers" />
            <h3 className="section__sub-heading">Keynotes by</h3>
            <div className="grid">
              <SpeakersFeatured className="grid__col-sm-6 grid__col-12">
                <figure>
                  <a href="speakers#ken"><img src={speakerKenKocienda} alt="Ken Kocienda" /></a>
                </figure>
                <article>
                  <h3>Ken Kocienda</h3>
                  <p>Software Engineer and Designer, Author</p>
                  <p>Over 15 years at Apple working on the original Safari web browser for the iPhone, iPad and Apple Watch. Acclaimed author of best selling WSJ book, "Creative Selection: Inside Apple’s Design Process During the Golden Age of Steve Jobs."</p>
                </article>
              </SpeakersFeatured>
              <SpeakersFeatured className="grid__col-sm-6 grid__col-12">
                <figure>
                  <a href="speakers#kate"><img src={speakerKateONeill} alt="Kate O'Neill" /></a>
                </figure>
                <article>
                  <h3>Kate O'Neill</h3>
                  <p>Experience Strategy Expert, Author, Founder and CEO of KO Insights</p>
                  <p>Kate is helping humanity prepare for a more technology-centric future by teaching business how to design data-driven experiences that are both more profitable and more meaningful for humans. Author of, "Tech Humanist: How You Can Make Technology Better for Business and Better for Humans."</p>
                </article>
              </SpeakersFeatured>
            </div>
            <h3 className="section__sub-heading">12 regular sessions over two days</h3>
            <SpeakersRow homePage speakers={speakersList} />
            <Button bgPurple url="/speakers" text="View All Speakers" />
          </Container>
        </SectionSpeakers>
        <SectionSponsors>
          <Container>
            <SectionHeading borderYellow heading="Sponsors" />
            <ul className="grid sponsors__list">
              <li className="grid__col-md-12">
                <a href="https://1password.com">
                  <img src={logoSponsor1} alt="1Password" />
                </a>
              </li>
              <li className="grid__col-md-12">
                <a href="https://medium.com">
                  <img src={logoSponsor2} className="s" alt="Medium" />
                </a>
              </li>
              <li className="grid__col-md-12">
                <a href="https://lightspeedpos.com">
                  <img src={logoSponsor4} className="s" alt="Lightspeed" />
                </a>
              </li>
            </ul>
          </Container>
        </SectionSponsors>
      </main>
    );
  }
}

export default Home;

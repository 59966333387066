import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './components/Container';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';

const SectionPictures = styled.section`
  padding: 115px 0 175px;
`;

const Text = styled.p`
  text-align: center;
`;

const Form = styled.form`
  max-width: 750px;
  margin: 45px auto 0;
  input,
  textarea {
    width: 100%;
    margin: 0 0 25px;
  }
  input,
  textarea {
    font-size: 20px;
    padding: 17px 15px;
  }
  input:not([type='submit']),
  textarea {
    color: var(--text-color);
    background: 0;
    border: 3px solid var(--text-color);
  }
  input[type='file'] {
    line-height: .3;
  }
  input[type='submit'] {
    display: block;
    width: 200px;
    margin: 45px auto 0;
    background: var(--accent-color--yellow);
  }
`;

class PhotoUpload extends Component {
  componentDidMount() {
    document.title = "NSNorth | Upload Photos";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main className={`${ this.props.className}`}>
		<SectionHero bgSpeakers heading="Photo Upload" />
        <SectionPictures>
          <Container>
            <SectionHeading marginSmall borderPurple heading="Upload Your Photos" />
            <Text>Please use the form below to upload your photos.<br/>We will review and add them to the site for everyone to enjoy.</Text>
            <Text>Note: Please keep this photo upload link private to help us avoid abuse.</Text>
            <Form action="upload.php" method="post" encType="multipart/form-data">
              <input type="text" name="name" placeholder="Name" required />
              <input type="email" name="email" placeholder="Email" required />
              <textarea name="description" placeholder="Description" rows="5" cols="60"></textarea>
              <input type="file" name="filename" required /> Max upload is 200Mb. Multiple files can be uploaded in a zip archive. Any standard image format allowed.
              <input type="submit" value="Submit" />
            </Form>
          </Container>
        </SectionPictures>
      </main>
    );
  }
}

export default PhotoUpload;
